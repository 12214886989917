'use strict'
/**
 * Application entry point
 */

import axios from 'axios';
// ================================
// START YOUR APP HERE
// ================================
window.submitForm = async () => {
    const name = document.querySelector('.js-name').value;
    const email = document.querySelector('.js-email').value;
    const message = document.querySelector('.js-message').value;

    const submitButton = document.querySelector('.js-form-submit');
    const successEl = document.querySelector('.js-form-success');
    const errorEl = document.querySelector('.js-form-error');
    const formEl = document.querySelector('.js-form');

    const postData = {
        name,
        email,
        message
    };

    errorEl.classList.add("c-form--hidden");
    submitButton.setAttribute("disabled", "true");
    try {
        const { data } = await axios.post('https://pely2mqwe7.execute-api.us-east-1.amazonaws.com/v1/', postData);
        successEl.classList.remove("c-form--hidden");
        formEl.classList.add("c-form--hidden");
    } catch (err) {
        successEl.classList.add("c-form--hidden");
        errorEl.classList.remove("c-form--hidden");
        formEl.classList.remove("c-form--hidden");
    }
    submitButton.removeAttribute("disabled");
}
